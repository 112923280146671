// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Label.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Label.tsx");
  import.meta.hot.lastModified = "1713348892162.7715";
}
// REMIX HMR END

import * as React from "react";
import { cn } from "~/utils/cn";
import { InfoIconTooltip } from "./Tooltip";
const variants = {
  small: {
    text: "font-sans text-sm font-normal text-text-bright leading-tight flex items-center gap-1"
  },
  medium: {
    text: "font-sans text-sm text-text-bright leading-tight flex items-center gap-1"
  },
  large: {
    text: "font-sans text-base font-medium text-text-bright leading-tight flex items-center gap-1"
  }
};
export function Label({
  className,
  children,
  variant = "medium",
  required = true,
  tooltip,
  ...props
}) {
  const variation = variants[variant];
  return <label className={cn(variation.text, className)} {...props}>
      {children}
      {tooltip ? <InfoIconTooltip content={tooltip} /> : null}
      {!required && <span className="text-text-dimmed"> (optional)</span>}
    </label>;
}
_c = Label;
var _c;
$RefreshReg$(_c, "Label");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;